import React from 'react';
import './VistaCertificado.css';

const VistaCertificado = ({ selectedUser, cursoData, questions }) => {
  const cursoNombre = cursoData?.curso || '';
  const email = selectedUser.email;
  const formattedDate = new Date(selectedUser.termino).toLocaleDateString('es-ES');
  const formattedTime = new Date(selectedUser.termino).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const puntaje = Math.floor(parseFloat(selectedUser.porcentaje.replace(',', '.')));
  const puntajeFinal = puntaje === 99 ? 100 : puntaje;

  return (
    <div className='certificado-container'>
      <h1 className='cert-titulo'>Plataforma CODELCO VR</h1>
      <p className={`${puntajeFinal >= 99 ? 'cert-aprobado' : 'cert-reprobado'}`}>
      </p>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p className='cert-curso'><span>Curso:</span> {`${cursoNombre} - ${selectedUser.modulo}`}</p>
        <p className='cert-puntaje'>Puntaje:<span>{puntajeFinal}%</span></p>
      </div>
      
      <p className='cert-email'>{email}</p>
      <div className='cert-questions'>
        {questions.map((question, index) => (
          <div key={index} className='cert-question'>
            <div className='question-content'>
              <p className='question-title'>Pregunta {index + 1}</p>
              <p className='question-text'>{question.text}</p>
            </div>
            <div className='question-status'>
              {question.approved ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="11" fill="#7aac4b"></circle>
                  <path fill="none" stroke="white" strokeWidth="2.5" d="M7 12l3 3l7-7"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="11" fill="#c3322d"></circle>
                  <path fill="none" stroke="white" strokeWidth="2" d="M8,8l8,8m0-8l-8,8"></path>
                </svg>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className='footer'>
        <div className='footer-item'>
          <span className="material-symbols-outlined">event_note</span>
          Fecha: {formattedDate}
        </div>
        <div className='footer-item'>
          <span className="material-symbols-outlined">schedule</span>
          Hora: {formattedTime}
        </div>
      </div>

    </div>
  );
};

export default VistaCertificado;