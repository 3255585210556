import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { createClient } from '@supabase/supabase-js';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import Dashboard from './pages/Dashboard';
import VistaModulo from './pages/VistaModulo';
import Home from './pages/Home';
import './index.css'
import NotFound from './pages/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import VistaCertificado from './pages/VistaCertificado';
import Logout from './pages/Logout';
import UsuarioPerfil from './pages/UsuarioPerfil';
import VistaResultado from './pages/VistaResultado';
import Header from './objetos/Header';
import { UserContextProvider } from './objetos/UserContext';
import Cursos from './pages/Cursos';
import Curso from './pages/Curso';
import Usuarios from './pages/Usuarios';


// import { useParams } from 'react-router-dom';

// Configuración de Supabase
const supabaseUrl = import.meta.env.VITE_SB_URL;
const supabaseKey = import.meta.env.VITE_SB_KEY;
const supabaseClient = createClient(supabaseUrl, supabaseKey);

console.log(supabaseClient);

// Crear un tema de MUI
const theme = createTheme({
    palette: {
        mode: 'light', // o 'dark', según tu preferencia
    },
});

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route element={
              <>
                <Header />
                <Outlet />
              </>
            }>
              {/* <Route path="/dashboard/*" element={
                <ProtectedRoute>
                  <Routes>
                    <Route index element={<Dashboard />} />
                    <Route path=":idcurso/:url" element={<VistaModulo />} />
                    <Route path="Certificado" element={<VistaCertificado />} />

                  </Routes>
                </ProtectedRoute>
              } /> */}
               <Route path="/cursos" element={
                <ProtectedRoute>
                  <Cursos />
                </ProtectedRoute>
              } />

              <Route path="/cursos/:idcurso" element={
                <ProtectedRoute>
                  <Curso />
                </ProtectedRoute>
              } />

              <Route path="/cursos/:idcurso/:url" element={
                <ProtectedRoute>
                  <VistaModulo />
                </ProtectedRoute>
              } />

              
              <Route path="/Certificado" element={<VistaCertificado />} />

              <Route path="/usuario-perfil/:userId" element={
                <ProtectedRoute>
                  <UsuarioPerfil />
                </ProtectedRoute>
              } />
              <Route path="/usuario-perfil/:userId/RC09/:intento" element={
                <ProtectedRoute>
                  <VistaResultado isFromDashboard={false} />
                </ProtectedRoute>
              } />

              <Route path="/usuarios/" element={
                <ProtectedRoute>
                  <Usuarios />
                </ProtectedRoute>
              } />

              <Route path="/salir" element={<Logout />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </UserContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SessionContextProvider supabaseClient={supabaseClient}>
      <App />
    </SessionContextProvider>
  </React.StrictMode>
);