import React, { useState, useEffect } from 'react';
import { useUser } from '@supabase/auth-helpers-react';
import { supabase } from './../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import TablaUsuarios from './Usuarios';
import Footer from '../objetos/Footer';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { LinearProgress, linearProgressClasses } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ value }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#e0e0e0',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: (() => {
        if (value < 34) return '#FF0000';
        if (value < 70) return '#FFD700';
        return '#00FF00';
      })(),
    },
}));

const Cursos = () => {
    const userContext = useUser();
    const [userInfo, setUserInfo] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [cursos, setCursos] = useState([]);
    const [isSortedAsc, setIsSortedAsc] = useState(true);
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const [cursosProgreso, setCursosProgreso] = useState({});
    const [usuariosPorCurso, setUsuariosPorCurso] = useState({});
    const [isFilteredByGraduates, setIsFilteredByGraduates] = useState(false);

    const fetchCursos = async () => {
        try {
            const { data, error } = await supabase.rpc('get_all_cursos');
            if (error) throw error;

            console.log('Cursos obtenidos:', data);
            setCursos(data);

            // Actualizar progreso y usuarios por curso
            const progresoPorCurso = {};
            const usuariosPorCursoData = {};

            data.forEach(curso => {
                const key = curso.idcurso;
                progresoPorCurso[key] = curso.promedio_porcentaje;
                usuariosPorCursoData[key] = curso.usuarios_unicos;
            });

            setCursosProgreso(progresoPorCurso);
            setUsuariosPorCurso(usuariosPorCursoData);

            console.log('Usuarios por curso:', usuariosPorCursoData);
        } catch (error) {
            console.error('Error al obtener los cursos:', error.message);
        }
    };

    useEffect(() => {
        fetchCursos();
    }, []);

    const handleSortByIdCurso = () => {
        setIsSortedAsc(prevIsSortedAsc => {
            const sortedCursos = [...cursos].sort((a, b) => {
                const idA = a.idcurso || '';
                const idB = b.idcurso || '';
                return prevIsSortedAsc ? idB.localeCompare(idA) : idA.localeCompare(idB);
            });

            setCursos(sortedCursos); // Actualiza el estado de los cursos después de ordenar
            console.log('Cursos después de ordenar:', sortedCursos.map(curso => curso.idcurso));
            return !prevIsSortedAsc; // Alterna el estado para cambiar el orden
        });
    };

    const handleFilterByGraduates = () => {
        if (isFilteredByGraduates) {
            // Si el filtro está activo, mostrar todos los cursos
            fetchCursos(); // Llama a fetchCursos para recargar todos los cursos
        } else {
            // Si el filtro no está activo, filtrar por cursos con graduados
            const filteredByGraduates = cursos.filter(curso => {
                const key = curso.idcurso;
                return (usuariosPorCurso[key] || 0) > 0;
            });
            setCursos(filteredByGraduates);
        }
        setIsFilteredByGraduates(!isFilteredByGraduates); // Alterna el estado del filtro
    };

    const filteredCursos = cursos.filter(curso => {
        const cursoName = curso.curso || '';
        const cursoId = curso.idcurso || '';
        return cursoName.toLowerCase().includes(searchTerm.toLowerCase()) ||
               cursoId.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return (
        <div className="container mt-10" style={{ minHeight: '100vh', overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
            <style>
                {`
                    .container::-webkit-scrollbar {
                        display: none;
                    }
                `}
            </style>
            <div className="search-container d-flex justify-content-end">
                <div className="search-bar d-flex align-items-center mr-12">
                    <input 
                        type="text" 
                        placeholder="BUSCAR CURSO" 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                    />
                    <button type="button" className="search-button">
                        <span className="icon-button-order" onClick={handleSortByIdCurso}>
                            <span className="material-symbols-outlined">
                                sort_by_alpha
                            </span>
                        </span>
                    </button>
                    <button type="button" className="search-button">
                        <span className="icon-button-order" onClick={handleFilterByGraduates}>
                            <span className="material-symbols-outlined">
                                school
                            </span>
                        </span>
                    </button>
                </div>
            </div>
            <div className="tarjetas-container">
                {filteredCursos.length > 0 ? (
                    filteredCursos.map(curso => {
                        const key = curso.idcurso || curso.curso;
                        return (
                            <div 
                                className="card" 
                                key={key}
                                onClick={() => navigate(`/cursos/${curso.idcurso}`)}
                                style={{ cursor: 'pointer' }}
                            >
                                <h2 className='idcurso' style={{ color: curso.color }}>{curso.idcurso}</h2>
                                <figure className="text-center">
                                    <img 
                                        src={`${curso.url_portada}?v=${new Date().getTime()}`} 
                                        alt={`Imagen del curso ${curso.curso}`} 
                                    />
                                </figure>
                                <div className="card-body">
                                    <h3 style={{ height: '64px' }}>{curso.curso}</h3>
                                    <div style={{ marginTop: '30px' }}>
                                        <BorderLinearProgress 
                                            variant="determinate" 
                                            value={cursosProgreso[key] || 0}
                                        />
                                        <p style={{ 
                                            textAlign: 'center', 
                                            marginTop: '10px', 
                                            fontSize: '20px', 
                                            fontWeight: 'bold',
                                            color: (() => {
                                                const value = cursosProgreso[key] || 0;
                                                if (value < 34) return '#FF0000';
                                                if (value < 70) return '#FFD700';
                                                return '#00FF00';
                                            })()
                                        }}>
                                            {cursosProgreso[key] || 0}%
                                        </p>
                                    </div>
                                    <div style={{ 
                                        display: 'flex', 
                                        alignItems: 'center', 
                                        justifyContent: 'flex-start', 
                                        marginTop: '30px', 
                                        paddingTop: '10px',
                                        fontSize: '18px',
                                    }}>
                                        <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>
                                            group
                                        </span>
                                        <span>{usuariosPorCurso[key] || 0} graduados</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <p>No hay cursos disponibles.</p>
                )}
            </div>
        </div>
    );
};

export default Cursos;