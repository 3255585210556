import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useTheme } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import html2pdf from 'html2pdf.js';
import VistaCertificado from './VistaCertificado';
import './VistaResultado.css';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const VistaResultado = ({ userId: propUserId, intento: propIntento, showNavBar = true, isFromDashboard = false }) => {
  const theme = useTheme();
  const { userId: paramUserId, intento: paramIntento } = useParams();
  const location = useLocation();
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const supabaseClient = useSupabaseClient();
  const navigate = useNavigate();
  const [entendimientoUrls, setEntendimientoUrls] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionScreenshots, setQuestionScreenshots] = useState([]);
  const [cursoData, setCursoData] = useState(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Usa los props si están disponibles, de lo contrario usa los parámetros de la URL
  const userId = propUserId || paramUserId;
  const intento = propIntento || paramIntento;
  const showNavBarFromState = location.state?.showNavBar ?? showNavBar;

  // Calcular puntaje y puntajeFinal solo si selectedUser no es null
  const puntajeFinal = selectedUser ? Math.floor(parseFloat(selectedUser.porcentaje.replace(',', '.'))) === 99 ? 100 : Math.floor(parseFloat(selectedUser.porcentaje.replace(',', '.'))) : 0; // Si no hay usuario, puntajeFinal es 0

  const handleDownloadPDF = async () => {
    console.log('Generando PDF...');
    const element = document.createElement('div');
    const root = createRoot(element);
    
    // Asegúrate de que selectedUser tenga datos antes de pasar
    if (!selectedUser) {
      console.error('No hay usuario seleccionado');
      return; // Salir si no hay usuario seleccionado
    }

    // Renderiza el componente y pasa selectedUser y el nombre de la tabla
    root.render(
      <div style={{ backgroundColor: 'white', color: 'black', padding: '20px' }}>
        <VistaCertificado selectedUser={selectedUser} cursoData={cursoData} questions={questions} />
      </div>
    );

    console.log(element.innerHTML); // Verifica el contenido

    setTimeout(async () => {
      try {
        await html2pdf()
          .from(element)
          .save(`Certificado-${selectedUser.idcurso}-${selectedUser.modulo}-${selectedUser.email}.pdf`); // Incluye idcurso, modulo y email en el nombre del archivo
      } catch (err) {
        console.error('Error al generar el PDF:', err);
      }
    }, 100);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      if (!userId || !intento) {
        console.error('userId o intento no proporcionados');
        setIsLoading(false);
        return;
      }

      try {
        const { data, error } = await supabaseClient
          .from('graduados')
          .select('*')
          .eq('iduser', userId)
          .eq('intento', intento)
          .single();

        if (error) throw error;
        setSelectedUser(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [userId, intento, supabaseClient]);

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        if (!selectedUser?.idcurso || !selectedUser?.modulo) {
          console.error('idcurso o modulo no están disponibles');
          return;
        }
        const { data, error } = await supabaseClient
          .from('modulos')
          .select('*')
          .eq('idcurso', selectedUser.idcurso)
          .eq('modulo', selectedUser.modulo)
          .single();

        if (error) {
          console.error('Error al obtener datos del módulo:', error);
          return;
        }

        const questionsData = [
          { pregunta: 'Pregunta 1:', text: data.pregunta1, approved: selectedUser.resultadopregunta1 === 'correcta' },
          { pregunta: 'Pregunta 2:', text: data.pregunta2, approved: selectedUser.resultadopregunta2 === 'correcta' },
          { pregunta: 'Pregunta 3:', text: data.pregunta3, approved: selectedUser.resultadopregunta3 === 'correcta' },
          { pregunta: 'Pregunta 4:', text: data.pregunta4, approved: selectedUser.resultadopregunta4 === 'correcta' },
          { pregunta: 'Pregunta 5:', text: data.pregunta5, approved: selectedUser.resultadopregunta5 === 'correcta' },
          { pregunta: 'Pregunta 6:', text: data.pregunta6, approved: selectedUser.resultadopregunta6 === 'correcta' },
          { pregunta: 'Pregunta 7:', text: data.pregunta7, approved: selectedUser.resultadopregunta7 === 'correcta' }
        ].filter(question => question.text !== null && question.text.trim() !== '');

        const questionScreenshotsData = [
          { number: 1, correct: data.url_pregunta1_buena, incorrect: data.url_pregunta1_mala },
          { number: 2, correct: data.url_pregunta2_buena, incorrect: data.url_pregunta2_mala },
          { number: 3, correct: data.url_pregunta3_buena, incorrect: data.url_pregunta3_mala },
          { number: 4, correct: data.url_pregunta4_buena, incorrect: data.url_pregunta4_mala },
          { number: 5, correct: data.url_pregunta5_buena, incorrect: data.url_pregunta5_mala },
          { number: 6, correct: data.url_pregunta6_buena, incorrect: data.url_pregunta6_mala },
          { number: 7, correct: data.url_pregunta7_buena, incorrect: data.url_pregunta7_mala }
        ];

        // Agregar URLs de entendimiento si no son null
        const entendimientoUrlsData = [
          data.url_entendimiento1,
          data.url_entendimiento2,
          data.url_entendimiento3,
          data.url_entendimiento4,
          data.url_entendimiento5,
          data.url_entendimiento6
        ].filter(url => url !== null);

        setQuestions(questionsData);
        setQuestionScreenshots(questionScreenshotsData);
        setEntendimientoUrls(entendimientoUrlsData); // Establecer las URLs de entendimiento

      } catch (error) {
        console.error('Error fetching module data:', error);
      }
    };

    if (selectedUser) {
      fetchModuleData();
    }
  }, [selectedUser, supabaseClient]);

  useEffect(() => {
    const fetchCursoData = async () => {
      if (!selectedUser?.idcurso) {
        console.error('idcurso no está disponible');
        return;
      }

      try {
        const { data, error } = await supabaseClient
          .from('cursos')
          .select('curso, url_portada')
          .eq('idcurso', selectedUser.idcurso)
          .single();

        if (error) {
          console.error('Error al obtener datos del curso:', error);
          return;
        }

        setCursoData(data);
      } catch (error) {
        console.error('Error fetching curso data:', error);
      }
    };

    if (selectedUser) {
      fetchCursoData();
    }
  }, [selectedUser, supabaseClient]);

  const openGallery = (images, index) => {
    const filteredImages = images.filter(image => image.original !== null);
    console.log('Opening gallery with images:', filteredImages);
    console.log('Starting index:', index);
    setGalleryImages(filteredImages);
    setCurrentImageIndex(index);
    setIsGalleryOpen(true);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };

  const handleSlide = (index) => {
    console.log('Current slide index:', index);
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    if (selectedUser) {
      console.log('Estado de las respuestas del usuario:', {
        pregunta1: selectedUser.resultadopregunta1,
        pregunta2: selectedUser.resultadopregunta2,
        pregunta3: selectedUser.resultadopregunta3,
        pregunta4: selectedUser.resultadopregunta4,
        pregunta5: selectedUser.resultadopregunta5,
        pregunta6: selectedUser.resultadopregunta6,
        pregunta7: selectedUser.resultadopregunta7,
      });
    }
  }, [selectedUser]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (!selectedUser) {
    return <div>No se encontraron datos para este usuario e intento.</div>;
  }

  const getBadgeStyle = (isCorrect) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 8px',
    borderRadius: '20px',
    fontSize: '0.875rem',
    lineHeight: '1',
    fontWeight: '500',
    color: 'white',
    backgroundColor: isCorrect ? '#10B981' : '#EF4444', // verde para correcto, rojo para incorrecto
  });

  return (
    <div 
      className={`no-select ${showNavBarFromState ? 'flex justify-center' : ''} min-h-screen bg-gray-100`}
      tabIndex="-1"
    >
      {/* Modal para la galería de imágenes */}
      <Modal
        isOpen={isGalleryOpen}
        onRequestClose={closeGallery}
        contentLabel="Galería de Imágenes"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '90%',
            maxHeight: '90%',
            overflow: 'hidden',
          },
        }}
      >
        <ImageGallery
          items={galleryImages}
          startIndex={currentImageIndex}
          onSlide={handleSlide}
          showThumbnails={false}
          showFullscreenButton={false}
          showPlayButton={false}
          onErrorImageURL="default-image-url"
        />
        <button 
          onClick={closeGallery} 
          style={{ 
            position: 'absolute', 
            top: 1, 
            right: 1, 
            background: 'none', 
            border: 'none', 
            cursor: 'pointer', 
            outline: 'none'
          }}
          tabIndex="-1"
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </Modal>

      <div 
        className={`no-select no-focus ${showNavBarFromState ? 'max-w-[900px]' : 'w-full'} px-4 py-8 overflow-y-auto`}
        tabIndex="-1"
      >
        {/* Encabezado */}
        <div className={`flex items-center mb-8 ${theme.palette.mode === 'dark' ? 'text-gray-200' : 'text-gray-800'}`}>
          <img 
            src={`${cursoData?.url_portada || 'default-image-url'}?v=${new Date().getTime()}`} 
            alt="Portada del curso" 
            className="w-20 h-20 mr-4" 
          />
          <h1 className="text-2xl font-bold titulo-curso">{cursoData?.curso || 'Título del Curso'}</h1>
        </div>
        
        {/* Mostrar el botón de volver solo si no es desde el Dashboard */}
        {!isFromDashboard && ( // Condición para mostrar el botón
          <span className="material-symbols-outlined">
            <span className="btn-volver" onClick={() => navigate(-1)}>
              reply
            </span>
          </span>
        )}
        
        {/* Información del usuario y botón de descarga */}
        <div className="flex justify-between items-start mb-8">
          <div className="flex flex-col datos-usuario">
            <p className="font-bold">
              <a href={`/usuario-perfil/${selectedUser?.iduser}`} className="text-blue-500 underline">
                {selectedUser?.email?.toLowerCase() || ''}
              </a>
            </p>
            <p>Curso: {selectedUser?.idcurso || ''}</p>
            <p>Modulo: {selectedUser?.modulo || ''}</p>
            <p>Puntaje: {selectedUser?.puntaje || ''}</p>
            <p>Código: {selectedUser?.intento || ''}</p>
          </div>

          <div className="flex flex-col items-center">
            {/* Span para mostrar aprobado o reprobado */}
            <p className={`${puntajeFinal >= 99 ? 'usuario-aprobado' : 'usuario-reprobado'}`}>
              {puntajeFinal >= 99 ? 'Aprobado' : 'Reprobado'}
            </p>
            {/* Puntaje */}
            <p className='cert-puntaje'><span>{puntajeFinal}%</span></p>

            {/* Botón de descarga */}
            <button 
              className="btn-download"
              onClick={handleDownloadPDF}
            >
              <span className="material-symbols-outlined mr-2">
                download
              </span>
              Descargar Informe
            </button>
          </div>
        </div>

        {/* Declaraciones de entendimiento */}
        <div className="mb-20">
          <div className="bg-white shadow-md rounded-lg p-4">
            <h2 className="text-xl font-bold mb-4 mt-4 text-center">DECLARACIONES DE ENTENDIMIENTO</h2>
            <div className="flex flex-row flex-wrap justify-center space-x-4">
              {entendimientoUrls.map((url, index) => (
                <div key={index} className="w-full max-w-[300px] mt-10">
                  <img
                    src={url}
                    alt={`Declaración entendimiento ${index + 1}`}
                    className="w-full h-auto object-contain cursor-pointer"
                    onClick={() => openGallery(entendimientoUrls.map(url => ({ original: url })), index)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Preguntas y respuestas */}
        <div className="mb-20">
          <h2 className="text-xl font-bold mb-4 text-center">PREGUNTAS</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {questions.map((question, index) => {
              const questionNumber = index + 1;
              const questionKey = `resultadopregunta${questionNumber}`;
              const isCorrect = selectedUser[questionKey] === 'correcta';
              const screenshot = questionScreenshots.find(s => s.number === questionNumber);
              const screenshotFilename = isCorrect ? screenshot.correct : screenshot.incorrect;
              const badgeText = isCorrect ? 'Correcta' : 'Incorrecta';

              return (
                <div className="bg-white shadow-md rounded-lg overflow-hidden" key={questionNumber}>
                  <img 
                    src={screenshotFilename} 
                    alt={`Respuesta ${badgeText}`} 
                    className="w-full h-48 object-cover cursor-pointer"
                    onClick={() => openGallery(
                      questionScreenshots.map(s => ({
                        original: selectedUser[`resultadopregunta${s.number}`] === 'correcta' ? s.correct : s.incorrect
                      })), 
                      index
                    )}
                  />
                  <div className="p-4">
                    <p className="text-sm text-gray-600">Pregunta {questionNumber}</p>
                    <h3 className="font-bold mt-1">{question.text.toUpperCase()}</h3>
                    <div style={getBadgeStyle(isCorrect)}>
                      {badgeText}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Zonas prohibidas */}
        {/* <div className="mb-20">
          <div className="bg-white shadow-md rounded p-4">
            <h2 className="text-xl font-bold mb-4 text-center col-span-full">ZONAS PROHIBIDAS</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {selectedUser.zonaprohibida1 === 'si' && (
                <div className="bg-white shadow-md rounded-lg overflow-hidden">
                  <img 
                    src="https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Zona-prohibida-1.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNo/RC09/Zona-prohibida-1.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvWm9uYS1wcm9oaWJpZGEtMS5qcGciLCJpYXQiOjE3MTU1NzQxNzIsImV4cCI6MTc0NzExMDE3Mn0.QptNEp4Ku33-_rFyrW32vZS7NcbF8c01ObN7xGux6Ek&t=2024-05-13T04%3A22%3A52.675Z" 
                    alt="Infracción Zona 1"
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="font-bold">Ingresó a Zona Prohibida 1</h3>
                  </div>
                </div>
              )}
              {selectedUser.zonaprohibida2 === 'si' && (
                <div className="flex flex-col justify-center items-center mt-10">
                  <p className="text-lg font-bold mb-2">Ingresó a Zona Prohibida 2</p>
                  <img src="https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Zona-prohibida-2.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvWm9uYS1wcm9oaWJpZGEtMi5qcGciLCJpYXQiOjE3MTU1NzQxODYsImV4cCI6MTc0NzExMDE4Nn0.BTVmoxiRusyg4u3KqY6UkluBKyirurhwiCZC8fSkS-8&t=2024-05-13T04%3A23%3A06.174Z" 
                  alt="Infracción Zona 2"
                  style={{ maxWidth: '300px', width: '100%', textAlign: 'center', margin: '0 auto' }}
                  />
                </div>
              )}
              {selectedUser.zonaprohibida3 === 'si' && (
                <div className="flex flex-col justify-center items-center mt-10">
                  <p className="text-lg font-bold mb-2">Ingresó a Zona Prohibida 3</p>
                  <img src="https://wqiegurjbgtmbycgasfh.supabase.co/storage/v1/object/sign/codelco/RC09/Zona-prohibida-3.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJjb2RlbGNvL1JDMDkvWm9uYS1wcm9oaWJpZGEtMy5qcGciLCJpYXQiOjE3MTU1NzQxOTksImV4cCI6MTc0NzExMDE5OX0.CCrwhMgAjwg-YGc7-ATr37NGf4zhPZS7WdHEt_zoa0k&t=2024-05-13T04%3A23%3A19.760Z" 
                  alt="Infracción Zona 3" 
                  style={{ maxWidth: '300px', width: '100%', textAlign: 'center', margin: '0 auto' }}
                  />
                </div>
              )}
              {selectedUser.zonaprohibida1 !== 'si' && selectedUser.zonaprohibida2 !== 'si' && selectedUser.zonaprohibida3 !== 'si' && (
                <div className="col-span-full flex flex-col items-center justify-center">
                  <span className="material-symbols-outlined text-green-500 text-9xl mb-4">
                    task_alt
                  </span>
                  <p className="text-lg font-bold text-center">No entró a ninguna zona prohibida</p>
                </div>
              )}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default VistaResultado;