import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from './../lib/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LinearProgress, linearProgressClasses, Box, Typography, Paper } from '@mui/material';

import './Curso.css';

const BorderLinearProgress = styled(LinearProgress)(({ value }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#e0e0e0',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: (() => {
      if (value < 34) return '#FF0000';
      if (value < 70) return '#FFD700';
      return '#00FF00';
    })(),
  },
}));

const Curso = () => {
    const { idcurso } = useParams(); // Obtener el id del curso desde la URL
    const [cursos, setCursos] = useState([]);
    const navigate = useNavigate();

    const fetchModulosPorCurso = async () => {
        console.log('idcurso:', idcurso);
        console.log('idcurso:', typeof idcurso);
        try {
            const { data, error } = await supabase.rpc('get_modulos_por_curso', { p_idcurso: idcurso });
            if (error) throw error;
            console.log('data:', data);

            console.log('Datos recibidos de get_modulos_por_cursooooooo*****:', data);
            setCursos(data);
        } catch (error) {
            console.error('Error al cargar los módulos del curso:', error.message);
        }
    };

    useEffect(() => {
        console.log('idcurso:', idcurso);
        fetchModulosPorCurso();
    }, [idcurso]);

    return (
        <>
             <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
                        <span className="material-symbols-outlined">
                            <span className="btn-volver" onClick={() => navigate(-1)}>
                                reply
                            </span>
                        </span>
                        <Typography variant="h4" gutterBottom sx={{ ml: 2 }}>{idcurso}</Typography>
                    </Box>
                
            </Box>
            <div className="curso-container">
                {cursos.map((curso, key) => (
                    <div 
                        className="card" 
                        key={key}
                        onClick={() => navigate(`/cursos/${curso.idcurso}/${curso.url}`)}
                        style={{ cursor: 'pointer' }}
                    >
                        <h2 className='idcurso' style={{ color: curso.color }}>{curso.idcurso}</h2>
                        <h3>{curso.modulo}</h3>
                        <figure className="text-center">
                            <img 
                                src={`${curso.url_portada}?v=${new Date().getTime()}`} 
                                alt={`Imagen del curso ${curso.curso}`} 
                            />
                        </figure>
                        <div className="card-body">
                            <h3>{curso.curso}</h3>
                            <div style={{ marginTop: '30px' }}>
                                <BorderLinearProgress 
                                    variant="determinate" 
                                    value={curso.promedio_porcentaje || 0}
                                />
                                <p style={{ 
                                    textAlign: 'center', 
                                    marginTop: '10px', 
                                    fontSize: '20px', 
                                    fontWeight: 'bold',
                                    color: (() => {
                                        const value = curso.promedio_porcentaje || 0;
                                        if (value < 34) return '#FF0000';
                                        if (value < 70) return '#FFD700';
                                        return '#00FF00';
                                    })()
                                }}>
                                    {curso.promedio_porcentaje || 0}%
                                </p>
                            </div>
                            <div style={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'flex-start', 
                                marginTop: '30px', 
                                paddingTop: '10px',
                                fontSize: '18px',
                            }}>
                                <span className="material-symbols-outlined" style={{ marginRight: '5px' }}>
                                    group
                                </span>
                                <span>{curso.usuarios_unicos || 0} graduados</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Curso;